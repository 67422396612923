// firebase.js

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getDatabase } from "firebase/database"; 
const firebaseConfig = {

  apiKey: "AIzaSyBlzyGc9nH3CB2jE-kMQ_vu5UKB3YwIKTM",

  authDomain: "lpae-fe77a.firebaseapp.com",

  databaseURL: "https://lpae-fe77a-default-rtdb.firebaseio.com",

  projectId: "lpae-fe77a",

  storageBucket: "lpae-fe77a.appspot.com",

  messagingSenderId: "615943906209",

  appId: "1:615943906209:web:7c97c62468ca7d81eb21e0",

  measurementId: "G-GHWCGDG28E"

};




// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getDatabase(app); 

// Export the initialized Firebase app
export { app, db };
