<template>
  <div class="App">
    <div class="logo-container">
      <img src="@/assets/Footer.jpg" alt="Footer Image" class="imga" />
    </div>
    <div class="logo-container">
      <img src="@/assets/Ft.jpg" alt="Bottom Footer Image" class="imgf" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterPage',
};
</script>

<style scoped>
/* Add your CSS styles here */
</style>
