<template>
  <div class="logo-container App">
    <img src="@/assets/Main.jpg" alt="Logo" class="imgh" style="width: auto; height: 63px;" />
  </div>
</template>

<script>
export default {
  name: 'HeadPage',
};
</script>

<style scoped>
/* Add your CSS styles here */
</style>
